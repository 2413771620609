import { axios, API_ROUTES } from "@/config";

export default {
  async getAll(id, params) {
    let result = await axios.get(`${API_ROUTES.category.get}/${id}`, {
      params: params,
    });

    return result;
  },

  async stepGroup(data) {
    let result = await axios.post(`${API_ROUTES.stepGroup.save}`, data);

    return result;
  },

  async stepAttributes(data) {
    let result = await axios.post(`${API_ROUTES.stepAttributes.save}`, data);

    return result;
  },

  async getAllDeparts(params) {
    let result = await axios.get(`${API_ROUTES.departments.get}`, {
      params: params,
    });

    return result;
  },
  async getAllMuniciByDepart(id, params) {
    let result = await axios.get(`${API_ROUTES.departments.get}/${id}`, {
      params: params,
    });

    return result;
  },

  async getAllPriorities(params) {
    let result = await axios.get(`${API_ROUTES.priority.get}`, {
      params: params,
    });

    return result;
  },

  async save(data) {
    let result = await axios.post(`${API_ROUTES.publishFarm.save}`, data);

    return result;
  },
  async find(id, params) {
    let result = await axios.get(`${API_ROUTES.publishFarm.find}/${id}`, {
      params: params,
    });

    return result;
  },
  async edit(id, params) {
    let result = await axios.get(`${API_ROUTES.publishFarm.edit}/${id}`, {
      params: params,
    });

    return result;
  },
  async update(data) {
    let result = await axios.put(`${API_ROUTES.publishFarm.update}`, data);

    return result;
  },
  async getAllNames(url, params) {
    let result = await axios.get(`${API_ROUTES.pageName.get}/${url}`, {
      params: params,
    });
    return result;
  },

  async uploadImage(data) {
    let result = await axios.post(`${API_ROUTES.publishFarm.img}`, data);
    return result;
  },

  async verifyIdentification(data) {
    let result = await axios.post(
      `${API_ROUTES.verifyIdentification.save}`,
      data
    );
    return result;
  },
};
